<template>
  <div class="process-details" v-if="fetched && data">
    <vx-card title="Prozess Details" class="h-full">
      <div class="vx-row">
        <div class="vx-col lg:w-full w-full">
          <!-- Prozess-ID -->
          <div class="vx-row mt-0 mb-4">
            <div class="vx-col lg:w-1/2">
              <vs-input
                  class="w-full"
                  label="Prozess-ID"
                  ref="processId"
                  v-model="data.id"
                  name="processId"
                  type="text"
                  :disabled="!isCreateForm"
                  placeholder="Wird automatisch generiert"
              />
            </div>
          </div>

          <!-- Titel -->
          <div class="vx-row mb-4">
            <div class="vx-col lg:w-full">
              <vs-input
                  class="w-full"
                  label="Titel (Kurzbeschreibung des Vorgangs)"
                  v-model="data.title"
                  placeholder="Prozess-Titel eingeben"
                  name="title"
                  data-vv-as="Titel"
                  v-validate="'required'"
              />
              <span class="text-danger text-sm" v-if="errors.has('title')">{{
                  errors.first('title')
                }}</span>
            </div>
          </div>

          <!-- Status -->
          <div class="vx-row mb-4">
            <div class="vx-col lg:w-full">
              <label>Status</label>
              <v-select
                  v-model="data.status"
                  :options="statusOptions"
                  placeholder="Status auswählen"
                  class="w-full"
                  name="status"
                  data-vv-as="Status"
                  v-validate="'required'"
              ></v-select>
              <span class="text-danger text-sm" v-if="errors.has('status')">{{
                  errors.first('status')
                }}</span>
            </div>
          </div>


          <div class="vx-row mt-4">
            <div class="vx-col w-full sm:w-1/2">
              <label>Prozesstyp:</label>
              <mg-select
                  v-model="data.process_type_id"
                  :options="processTypes"
                  name="process_type"
                  placeholder="Prozesstyp auswählen"
                  track-by="id"
                  label="name"
                  data-vv-as="Prozesstyp"
                  v-validate="'required'"
              ></mg-select>
              <span class="text-danger text-sm" v-if="errors.has('process_type')">
      {{ errors.first('process_type') }}
    </span>
            </div>

            <div class="vx-col w-full sm:w-1/2">
              <label>Zugewiesener Nutzer:</label>
              <mg-select
                  v-model="data.assigned_user_id"
                  :options="users"
                  name="assigned_user"
                  placeholder="Benutzer auswählen"
                  track-by="id"
                  label="name"
                  data-vv-as="Zugewiesener Benutzer"
                  v-validate="'required'"
              ></mg-select>
              <span class="text-danger text-sm" v-if="errors.has('assigned_user')">
      {{ errors.first('assigned_user') }}
    </span>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-full">
              <label>Daten (JSON):</label>
              <vs-textarea
                  v-model="data.data"
                  class="w-full"
                  placeholder="JSON-Daten einfügen"
                  height="160px"
              ></vs-textarea>
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-full">
              <label>Notizen:</label>
              <vs-textarea
                  v-model="data.notes"
                  class="w-full"
                  placeholder="Notizen einfügen"
                  height="160px"
              ></vs-textarea>
            </div>
          </div>

          <!-- Erstellt und Geändert -->
          <div v-if="!isCreateForm" class="mt-4">
            <div>Erstellt am: {{ data.created_at | dateTime }}</div>
            <div>Geändert am: {{ data.updated_at | dateTime }}</div>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- Aktionen -->
    <div class="flex mt-8 justify-end">
      <vs-button
          color="warning"
          type="border"
          class="mb-4"
          @click="onClickDelete"
          v-if="!isCreateForm"
      >
        Prozess löschen
      </vs-button>
      <vs-button
          color="warning"
          type="border"
          class="ml-3 mb-4"
          :to="{ name: 'process-list' }"
      >
        Abbrechen
      </vs-button>
      <vs-button
          class="ml-3 mb-4"
          :disabled="!validateForm"
          @click="isCreateForm ? onCreate() : onSave()"
      >
        {{ isCreateForm ? "Prozess erstellen" : "Speichern" }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/api";
import vSelect from "vue-select";
import moment from "moment";
import mgSelect from "@/components/mg-select/MgSelect.vue";

export default {
  components: { vSelect, mgSelect },
  data() {
    return {
      data: null,
      fetched: false,
      userOptions: [], // Benutzer-Optionen für Auswahlfeld
      statusOptions: ["New", "In Progress", "On Hold", "Rejected", "Completed"],
      processTypes: [], // Prozesstypen für Auswahlfeld
      users: [], // Benutzer für Auswahlfeld

    };
  },
  computed: {
    validateForm() {
      return (
          !this.errors.any() &&
          this.data.process_type_id &&
          this.data.assigned_user_id &&
          this.data.created_by_user_id
      );
    },
    isCreateForm() {
      return !this.$route.params.id;
    },
  },
  methods: {
    fetchProcessTypes() {
      ApiService.get("process-type").then((response) => {
        if (response.data.status === "success") {
          this.processTypes = response.data.result;
        }
      });
    },
    fetchUsers() {
      ApiService.get("users").then((response) => {
        if (response.data.status === "success") {
          this.users = response.data.result.map((user) => ({
            id: user.id,
            name: `${user.detail.first_name} ${user.detail.last_name}`,
          }));
        }
      });
    },
    initializeData() {
      this.data = {
        process_type_id: null,
        assigned_user_id: null,
        created_by_user_id: this.$store.getters.userId,
        data: null,
        status: "New",
        title: null,
        notes: null,
        workspace_id: localStorage.getItem('current_workspace'),
      };
    },
    onCreate() {
      if (!this.validateForm) return;

      ApiService.post("process", this.data)
          .then((response) => {
            if (response.data.status === "success") {
              this.$vs.notify({
                title: "Erfolgreich",
                text: "Der Prozess wurde erfolgreich erstellt.",
                color: "success",
              });
              this.$router.push("/processes");
            } else {
              this.$vs.notify({
                title: "Fehlgeschlagen",
                text: "Der Prozess konnte nicht erstellt werden.",
                color: "danger",
              });
            }
          })
          .catch((error) => {
            this.$vs.notify({
              title: "Fehlgeschlagen",
              text: "Es ist ein Fehler aufgetreten.",
              color: "danger",
            });
          });
    },
    fetchData() {
      ApiService.get(`process/${this.$route.params.id}`).then((response) => {
        if (response.data.status === "success") {
          this.data = response.data.result;
        }
      });
    },
  },
  mounted() {
    this.fetchProcessTypes();
    this.fetchUsers();
    if (this.$route.params.id) {
      this.fetchData();
    } else {
      this.initializeData();
    }
    this.fetched = true;
  },

  filters: {
    dateTime(value) {
      return moment(value).format("DD.MM.YYYY HH:mm") + " Uhr";
    },
  },
};
</script>

<style scoped>
.process-details {
  .headerButtons {
    margin-bottom: 15px;
  }
}
</style>
